#root {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#logo {
  height: 100px;
  width: 100px;
}

h1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1%;
}

table:first-of-type {
  margin-top: 1%;
}

table {
  margin: 3% 20%;
  width: 60% !important;
}

thead {
  text-align: center;
  background-color: #3565be;
  color: white;
}

tr > td:not(:first-of-type) {
  text-align: center;
}

.fixed-width {
  font-family: var(--bs-font-monospace);
}

td.distance {
  text-align: end !important;
}

td.duration {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
